import React from "react"
import { graphql, Link } from "gatsby"
import * as style from "./index.module.sass"
import Layout from "../components/layout"
import Preview from "../components/preview/preview"

const IndexPage = ({ data }) => {
  const projects = data.allContentfulProject.edges
  return (
    <Layout>
      <div className={style.top} />
      {projects.map(project => (
        <Link>
          <Preview
            description={project.node.header.description}
            source={project.node.header.gatsbyImageData}
            name={project.node.titel + " // " + project.node.date}
            slug={project.node.slug}
          />
        </Link>
      ))}
      <div className={style.box} />
    </Layout>
  )
}
export default IndexPage

export const Head = () => {
  return (
    <>
      <title>AycaNoel</title>
      <meta
        name="description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
      <meta property="og:url" content="aycanoel.com" />
      <meta property="og:site_name" content="AycaNoel" />
      <meta
        property="og:description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
    </>
  )
}
export const query = graphql`
  query {
    allContentfulProject(sort: { fields: date, order: DESC }) {
      edges {
        node {
          date
          titel
          slug
          header {
            description
            gatsbyImageData
          }
        }
      }
    }
  }
`
